<template>
  <div class="outerwrapper">
    <div class="tophead">
      <div class="tophead-content">
        <div class="topbrand">
          Buchungsanfragen - Bildungsstätte Anne-Frank
        </div>
        <div class="topnav">
          <a href="https://www.bs-anne-frank.de/impressum/" target="_blank">Impressum</a>
          <a href="https://www.bs-anne-frank.de/ueber-uns/mitglied-werden/interesse-danke/datenschutz/" target="_blank">Datenschutz</a>
        </div>
      </div>
    </div>
    <div class="wrapper">
      <div class="header">
        <a href="https://www.bs-anne-frank.de/">
          <img src="../assets/logo_bs.png" width="102" height="151" class="logo"/>
        </a>
        <div class="headerimage">
          <div class="headerOverlay"/>
          <img src="../assets/workshops-1.jpg"/>
        </div>

      </div>
      <div class="contentzone">
        <slot/>
      </div>
    </div>
    <!--<div class="greyfoot">
        <div class="footer-content">bla</div>
    </div>-->
  </div>
</template>

<script>
  export default {
    name: 'basic-layout'
  }
</script>

<style scoped lang="scss">
  @import "../assets/config";

  $width: 980px; //980px;

  .wrapper {
    margin: 0 auto;
    max-width: $width;
    padding: 10px;
    padding-bottom: 15rem;
  }

  .header {
    position: relative;
    min-height: 150px;
    margin-top: 2rem;
    /*margin-bottom:4rem;*/
    padding: 65px 60px 60px 60px;

  }

  .headerimage img {
    display: block;
  }

  .headerimage {
    z-index: 10;
    position: relative;
  }

  .headerOverlay {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    right: 0;
    height: 125%;
    width: 100%;
  }

  .headerOverlay:after {
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    background-color: #01baff;
    opacity: .3;
    width: 75%;
    height: 100%;
    bottom: -30%;
    transform: skewY(-10deg);
  }

  .headerimage:before, .headerimage:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    left: 15px;
    width: 50%;
    top: 50%;
    max-width: 300px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.25);
    transform: rotate(-2.5deg);
  }

  .headerimage:after {
    transform: rotate(2.5deg);
    right: 15px;
    left: auto;
  }

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .contentzone {
    padding: 0 60px;
  }

  .tophead {
    width: 100%;
    background: #f6f7f9;
    border-bottom: 1px solid #e6e6e6;
    min-height: 30px;
  }

  .tophead-content {
    max-width: $width;
    margin: 0 auto;
    text-align: right;
    padding: 0 70px;
  }

  .topbrand {
    display: inline-block;
    width: 50%;
    text-align: left;
    font-size: 11px;
    color: #999999;
  }

  .topnav {
    display: inline-block;
    width: 50%;
  }

  .topnav a {
    list-style: none;
    font-size: 11px;
    color: #999999;
  }

  .topnav a:not(:first-child):before {
    content: ' | ';
    padding: 0 10px;
  }

  /*


  .greyfoot {
      width: 100%;
      background: #e9eaed;
      border-top: 1px solid #d4d8e1;
      min-height: 3rem;
  }
  .footer-content {
      margin: 0 auto;
      max-width: 980px;
      padding: 25px 60px;
  }
  */
  @media (max-width: $tablet) {
    .tophead-content {
      padding: 0 15px;
    }

    .contentzone {
      padding: 0 5px;
    }
  }
</style>

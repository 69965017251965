// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import {ApolloClient} from 'apollo-client'
import {HttpLink} from 'apollo-link-http'
import {ApolloLink} from "apollo-link"
import {onError} from 'apollo-link-error'
import {InMemoryCache} from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import Buefy from 'buefy'
import VueScrollTo from 'vue-scrollto'
//import 'buefy/lib/buefy.css'
//import '@mdi/font/css/materialdesignicons.css'
import moment from 'moment'
import vueMoment from 'vue-moment'
import router from './router'

moment.locale('de');
Vue.use(Buefy)
Vue.use(VueScrollTo)
/*Vue.component(Buefy.Field.name, Buefy.Field)
Vue.component(Buefy.Input.name, Buefy.Input)
Vue.component(Buefy.Select.name, Buefy.Select)
Vue.component(Buefy.Loading.name, Buefy.Loading)
Vue.component(Buefy.Message.name, Buefy.Message)
Vue.component(Buefy.Checkbox.name, Buefy.Checkbox)*/
Vue.use(vueMoment, {moment})

const API_BASE = process.env.NODE_ENV === 'production' ? '' : 'http://junoform.localhost'
const API_GRAPHQL = API_BASE + '/graphql'
const API_INIT_CSRF = API_BASE + '/csrf-cookie'


const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: API_GRAPHQL,
  credentials: 'include'
})

const setXSRF = (operation) => {
  let cookieVal = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
  operation.setContext({
    headers: {
      'X-XSRF-TOKEN': cookieVal
    }
  });
}

const xsrfLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the X-CSRF-TOKEN header back.
  setXSRF(operation)
  // Call the next link in the middleware chain.
  return forward(operation);
});

const errorLink = onError(({networkError, graphQLErrors, forward, operation}) => {
    // Refresh page on unauthenticated
    if (!!graphQLErrors && !!graphQLErrors[0] && graphQLErrors[0].message === "Unauthenticated.") {
      location.reload()
    }

    // Refresh token on mismatch
    if (!!networkError && !!networkError.result && !!networkError.result.message && networkError.result.message === "CSRF token mismatch.") {
      const promise = fetch(API_INIT_CSRF, {credentials: 'include', mode: 'cors'})
      return promiseToObservable(promise).flatMap(() => {
        setXSRF(operation);
        return forward(operation);
      });
    }
  }
)

// Create the apollo client
const apolloClient = new ApolloClient({
  link: xsrfLink.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true
})
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

// Install the vue plugin
Vue.use(VueApollo)
Vue.config.productionTip = false
/* eslint-disable no-new */
new Vue({
  el: '#app',
  apolloProvider,
  router,
  render: h => h(App)
}).$mount('#app')

fetch(API_INIT_CSRF, {credentials: 'include', mode: 'cors'}).then(async () => {
  new Vue({
    router,
    apolloProvider,
    render: h => h(App)
  }).$mount('#app')
})
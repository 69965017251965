<template>
<div>
  <slot></slot>
  <small v-if="!req" class="m-l-sm">(optional)</small>
</div>
</template>

<script>
  export default {
    name: "FieldLabel",
    props: ['req']
  }
</script>

<style scoped>
  small{
    margin-left: .25em;
    font-weight: normal;
  }

</style>

import Vue from 'vue'
import Router from 'vue-router'
import Registration from './views/Registration'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/:id',
      name: 'registration',
      props:true,
      component: Registration
    },
    {
      path: '/',
      name: 'Register',
      component: Registration
    },
    {
      path: '/page/:token',
      props: true,
      name: 'eventPage',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "eventpage" */ './views/EventPage.vue')
    }
  ]
})

<template>
  <div>
    <div v-if="!isSuccess">
      <b-message type="is-danger" v-if="isFailure">
        Entschuldigung, ein Fehler ist aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut,
        rufen Sie uns an oder schreiben Sie uns eine E-Mail.
      </b-message>
      <div class="headline is-first" :class="{'is-borderless' : !!selectedOffering.formInfoBeforeConfirm}">Anmeldung für
        {{selectedOffering.formInfoName}}
      </div>
      <b-message v-if="!!selectedOffering.formInfoBeforeConfirm">
        <div style="white-space: pre-wrap;">{{selectedOffering.formInfoBeforeConfirm}}</div>
      </b-message>
      <table>
        <tbody><!--
                <tr>
                    <th>Datum</th>
                    <td>{{regInfo.slot.begin | moment('dddd DD. MMMM YYYY')}}</td>
                </tr>
                <tr>
                    <th>Uhrzeit</th>
                    <td>{{regInfo.slot.begin | moment('HH:mm')}} - {{regInfo.slot.end | moment('HH:mm')}}</td>
                </tr>
                <tr v-if="regInfo.slot.isMultiPersonOffering">
                    <th>Personenzahl</th>
                    <td>{{regInfo.person.numberOfPeople}}</td>
                </tr>-->
        <tr>
          <th>Vorname</th>
          <td>{{regInfo.person.firstName}}</td>
        </tr>
        <tr>
          <th>Nachname</th>
          <td>{{regInfo.person.lastName}}</td>
        </tr>
        <tr v-if="!!regInfo.person.age">
          <th>Alter</th>
          <td>{{regInfo.person.age}} Jahre</td>
        </tr>
        <tr v-if="!!regInfo.person.institution">
          <th>Institution</th>
          <td>{{regInfo.person.institution}}</td>
        </tr>
        <tr v-if="!!regInfo.person.schoolType">
          <th>Schulform</th>
          <td>{{regInfo.person.schoolType}}</td>
        </tr>
        <tr v-if="!!regInfo.person.className">
          <th>Klasse</th>
          <td>{{regInfo.person.className}}</td>
        </tr>
        <tr v-if="!!regInfo.person.street">
          <th>Staße und Hausnummer</th>
          <td>{{regInfo.person.street}}</td>
        </tr>
        <tr v-if="!!regInfo.person.zip || !!regInfo.person.city">
          <th>Ort</th>
          <td>{{regInfo.person.zip}} {{regInfo.person.city}}</td>
        </tr>
        <tr>
          <th>E-Mail</th>
          <td>{{regInfo.person.email}}</td>
        </tr>
        <tr v-if="!!regInfo.person.phone">
          <th>Telefon</th>
          <td>{{regInfo.person.phone}}</td>
        </tr>
        <tr v-if="!!regInfo.person.note">
          <th>Kommentar</th>
          <td>{{regInfo.person.note}}</td>
        </tr>
        <tr v-for="field in regInfo.person.formFieldValues">
          <th>{{selectedOffering.formFields.find(f => f.id === field.id).label}}</th>
          <td>{{field.value}}</td>
        </tr>
        </tbody>
      </table>
      <br/>
      <p>Sind diese Angaben korrekt?</p>
      <div style="margin-top:1.5rem;">
        <button class="button" @click="register" :class="{'is-loading': isLoading}">Ja, absenden!</button>
        <button class="button is-pulled-right" @click="$emit('cancel')">Zurück</button>
      </div>
    </div>
    <template v-else>
      <div class="headline is-borderless is-first">Anmeldung erfolgreich</div>
      <b-message type="is-success">
        <div style="white-space: pre-wrap;">{{selectedOffering.formInfoAfterConfirm}}</div>
        <template v-if="!selectedOffering.formInfoAfterConfirm">Vielen Dank für Ihre Anmeldung!</template>
      </b-message>
    </template>
  </div>
</template>

<script>
  import BOOK_EVENT_MUTATION from '../graphql/BookEvent.gql'

  export default {
    name: 'confirm-box',
    props: ['regInfo', 'selectedOffering', 'selectedEvent'],
    data() {
      return {
        isLoading: false,
        isSuccess: false,
        isFailure: false
      }
    },
    methods: {
      register() {
        this.isLoading = true
        this.$apollo.mutate({
          mutation: BOOK_EVENT_MUTATION,
          variables: {
            eventId: this.regInfo.event ? this.regInfo.event.id : null,
            slotId: this.regInfo.slot ? this.regInfo.slot.id : null,
            person: this.regInfo.person
          }
        }).then(() => {
          this.isLoading = false
          this.isSuccess = true
        }).catch(() => {
          this.isLoading = false
          this.isFailure = true
        })
      }
    }
  }
</script>

<style scoped>

  table th {
    padding-right: 1rem;
    font-weight: 500;
  }

  table tr {
    line-height: 2rem;
  }

  .name {
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 0.6rem;
  }


</style>

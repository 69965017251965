<template>
  <div>
    <template v-if="selectedOffering.formFields && selectedOffering.formFields.length > 0">
      <div class="headline" style="margin-top:3rem;">Angaben zu Ihrem Besuch</div>
      <b-field v-for="({id, value}, key) in person.formFieldValues" :key="id"
               :type="{'is-danger': errors.formFieldValues && errors.formFieldValues.find(f => f === id)}">
        <template v-slot:label v-if="getField(id).type !== 'checkbox'">
          {{ getField(id).label }}<small style="font-weight: normal" v-if="!getField(id).required"> (optional)</small>
        </template>
        <template v-slot:message>{{ getField(id).description }}</template>
        <b-select placeholder="Bitte Wählen" v-if="getField(id).type === 'select'"
                  v-model="person.formFieldValues[key].value" expanded>
          <option v-for="val in getField(id).allowedValues" :key="val">{{ val }}</option>
        </b-select>
        <b-input v-if="getField(id).type === 'textInput'" v-model="person.formFieldValues[key].value"
                 :placeholder="getField(id).label"></b-input>
        <b-checkbox v-else-if="getField(id).type === 'checkbox'" v-model="person.formFieldValues[key].value"
                    :true-value="'Ja'"
                    :false-value="'Nein'">
          {{ getField(id).label }}
          <small style="font-weight: normal" v-if="!getField(id).required"> (optional)</small>
        </b-checkbox>
      </b-field>
    </template>
    <div class="headline" style="margin-top:3rem;">Kontaktdaten eingeben</div>
    <b-field :type="getType('isInstitution')"
             v-if="selectedOffering.formFieldIsInstitution">
      <field-label slot="label" :req="selectedOffering.formRequireIsInstitution">Institution / Privat</field-label>
      <b-select expanded v-model="person.isInstitution" placeholder="Bitte wählen">
        <option :value="true">Institution</option>
        <option :value="false">Privat</option>
      </b-select>
      <!--<b-checkbox placeholder="Institution" type="checkbox" v-model="person.isInstitution" expanded></b-checkbox>-->
    </b-field>
    <b-field
        :type="getType('institution')"
        v-if="selectedOffering.formFieldInstitution ?  (selectedOffering.formFieldIsInstitution ? person.isInstitution : true) : false">
      <field-label slot="label"
                   :req="selectedOffering.formRequireInstitution">
        {{ selectedOffering.formFieldIsInstitution ? 'Name der Institution' : 'Institution' }}
      </field-label>
      <b-input placeholder="Institution" v-model="person.institution" expanded></b-input>
    </b-field>
    <b-field :type="getType('schoolType')"
             v-if="selectedOffering.formFieldSchoolType">
      <field-label slot="label" :req="selectedOffering.formRequireSchoolType">Schulform</field-label>
      <b-input placeholder="Schulform" v-model="person.schoolType" expanded></b-input>
    </b-field>
    <b-field :type="getType('className')"
             v-if="selectedOffering.formFieldClass">
      <field-label slot="label" :req="selectedOffering.formRequireClass">Klasse</field-label>
      <b-input placeholder="Klasse" v-model="person.className" expanded></b-input>
    </b-field>
    <b-field :type="getType('salutation')"
             v-if="selectedOffering.formFieldSalutation">
      <field-label slot="label" :req="selectedOffering.formRequireSalutation">Anrede</field-label>
      <b-select placeholder="Anrede" v-model="person.salutation" class="no-danger" expanded>
        <option value="">Keine Angabe</option>
        <option value="Herr">Herr</option>
        <option value="Frau">Frau</option>
      </b-select>
    </b-field>

    <b-field label="Vorname" :type="getType('firstName')">
      <b-input placeholder="Vorname" v-model="person.firstName" expanded></b-input>
    </b-field>

    <b-field label="Nachname" :type="getType('lastName')">
      <b-input placeholder="Nachname" v-model="person.lastName" expanded></b-input>
    </b-field>

    <b-field :type="getType('age')"
             v-if="selectedOffering.formFieldAge">
      <field-label slot="label" :req="selectedOffering.formRequireAge">Alter</field-label>
      <b-input placeholder="Alter" type="number" min="1" v-model="person.age" expanded></b-input>
    </b-field>

    <b-field label="E-Mail" :type="getType('email')">
      <b-input placeholder="E-Mail"
               v-model="person.email"
               name="email"></b-input>
    </b-field>
    <b-field :type="getType('phone')" v-if="selectedOffering.formFieldPhone">
      <field-label slot="label" :req="selectedOffering.formRequirePhone">Telefon</field-label>
      <b-input placeholder="Telefon" v-model="person.phone"></b-input>
    </b-field>
    <label class="label" v-if="selectedOffering.formFieldStreet">
      <field-label :req="selectedOffering.formRequireStreet">
        {{ selectedOffering.formAddressIsInvoiceAddress ? 'Rechnungsadresse' : 'Adresse' }}
      </field-label>
    </label>
    <b-field :type="getType('street')" v-if="selectedOffering.formFieldStreet">
      <b-input placeholder="Straße und Hausnummer" v-model="person.street"></b-input>
    </b-field>
    <b-field grouped v-if="selectedOffering.formFieldCity">
      <b-field :type="getType('zip')" style="width:12em;">
        <field-label slot="label" :req="selectedOffering.formRequireCity">Postleitzahl</field-label>
        <b-input placeholder="Postleitzahl" v-model="person.zip"></b-input>
      </b-field>
      <b-field :type="getType('city')" expanded>
        <field-label slot="label" :req="selectedOffering.formRequireCity">Ort</field-label>
        <b-input placeholder="Ort" v-model="person.city"></b-input>
      </b-field>
    </b-field>
    <template v-if="selectedOffering.formFieldNote">
      <label class="label">
        <field-label :req="selectedOffering.formRequireNote">Kommentar</field-label>
      </label>
      <div class="noteMessage" v-if="selectedOffering.formInfoNoteMessage">
        <small>{{ selectedOffering.formInfoNoteMessage }}</small>
      </div>
      <b-field :type="getType('note')">
        <b-input type="textarea" maxlength="500" placeholder="Kommentar eingeben..."
                 v-model="person.note"></b-input>
      </b-field>
    </template>
    <b-field :type="getType('acceptDisclaimer')"
             :message="errors.acceptDisclaimer ? 'Sie müssen die Datenschutzerklärung akzeptieren' : ''">
      <b-checkbox type="" v-model="acceptDisclaimer">Ich akzeptiere die <a
          href="https://www.bs-anne-frank.de/ueber-uns/mitglied-werden/interesse-danke/datenschutz/" target="_blank">Datenschutzerklärung</a>
      </b-checkbox>
    </b-field>
    <b-field>
      <b-checkbox type="" v-model="person.acceptNewsletter">
        <field-label :req="false">Ich möchte den Newsletter abonnieren</field-label>
      </b-checkbox>
    </b-field>

    <div style="margin-top:2.5rem;">
      <button class="button is-medium" @click="submit" type="submit">Anmeldung abschließen</button>
    </div>
  </div>
</template>

<script>

import FieldLabel from "./FieldLabel";

export default {
  name: 'registration-form',
  components: {FieldLabel},
  data() {
    return {
      acceptDisclaimer: false,
      person: {
        salutation: null,
        firstName: '',
        lastName: '',
        age: null,
        institution: '',
        isInstitution: null,
        schoolType: '',
        className: '',
        street: '',
        zip: '',
        city: '',
        email: '',
        phone: '',
        note: '',
        acceptNewsletter: false,
        numberOfPeople: 1,
        formFieldValues: []
      },
      errors: {}
    }
  },
  props: ['selectedTime', 'selectedOffering', 'selectedEvent'],
  watch: {
    selectedTime(time) {
      if (!time.isMultiPersonOffering) {
        this.person.numberOfPeople = 1
      }
    },
    selectedOffering() {
      this.initFields()
    }
  },
  mounted() {
    this.initFields()

  },
  methods: {
    initFields() {
      this.person.formFieldValues = this.selectedOffering.formFields ?
          this.selectedOffering.formFields.map((f) => ({id: f.id, value: null}))
          : []
    },
    hasField(id) {
      return this.selectedOffering.formFields && !!this.selectedOffering.formFields.find(f => f.id === id)
    },
    getField(id) {
      return this.selectedOffering.formFields.find(f => f.id === id)
    },
    getType(field) {
      return !!this.errors[field] ? 'is-danger' : '';
    },
    req(string, yesno) {
      return string + (yesno ? '' : ' (optional)')
    },
    validateEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    submit() {
      this.errors = {}
      this.errors.acceptDisclaimer = !this.acceptDisclaimer
      this.errors.email = (!this.person.email || !this.validateEmail(this.person.email))
      this.errors.salutation = !this.person.salutation && this.selectedOffering.formRequireSalutation
      this.errors.firstName = !this.person.firstName
      this.errors.lastName = !this.person.lastName
      this.errors.age = !this.person.age && this.selectedOffering.formRequireAge
      this.errors.zip = !this.person.zip && this.selectedOffering.formRequireCity
      this.errors.city = !this.person.city && this.selectedOffering.formRequireCity
      this.errors.street = !this.person.street && this.selectedOffering.formRequireStreet
      this.errors.phone = !this.person.phone && this.selectedOffering.formRequirePhone
      this.errors.note = !this.person.note && this.selectedOffering.formRequireNote
      this.errors.className = !this.person.className && this.selectedOffering.formRequireClass
      this.errors.schoolType = !this.person.schoolType && this.selectedOffering.formRequireSchoolType
      this.errors.isInstitution = this.person.isInstitution === null && this.selectedOffering.formRequireIsInstitution
      this.errors.institution = !this.person.institution && this.selectedOffering.formRequireInstitution
      this.errors.formFieldValues = this.person.formFieldValues.filter(f => this.hasField(f.id) && this.getField(f.id).required && !f.value).map(f => f.id)


      this.errors.numberOfPeople = this.selectedEvent
          ? this.person.numberOfPeople > this.selectedEvent.countFreePlaces
          : this.person.numberOfPeople > this.selectedTime.countFreePlaces;


      let hasErrors = false
      for (let i in this.errors) {
        if (this.errors[i] === true) {
          hasErrors = true
        }
      }
      if (!hasErrors) {
        this.$emit('submit', this.person)
      }
    }
  }
}
</script>

<style scoped>
.noteMessage {
  margin-top: -.5rem;
  margin-bottom: .25rem;
}

</style>

<style>
</style>

<template>
    <div>
        <div class="calendar" :class="{'isLoading': loading}">
            <div class="controls">
                <a @click="subMonth" class="arrow is-unselectable">«</a>
                <div class="monthDisplay">{{this.dateContext | moment('MMMM YYYY')}}</div>
                <a @click="addMonth" class="arrow is-unselectable">»</a>
            </div>
            <div v-for="name in dayNames" :key="name" class="dayHead">{{name}}</div>
            <div class="days">
                <div class="loader-overlay" v-if="loading">
                    <div class="loader"></div>
                </div>
                <div v-for="day in displayDays" :key="day.id" class="day"
                     :class="dayClasses(day)">
                    <a v-if="day.active" @click="selectDate(day.date)">{{day.day}}</a>
                    <template v-else>{{day.day}}</template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'date-picker',
  props: ['slots', 'loading'],
  data () {
    return {
      dateContext: moment(),
      dayNames: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
      selectedDate: null
    }
  },
  methods: {
    subMonth () {
      this.dateContext = moment(this.dateContext.subtract(1, 'month'))
      this.monthChange()
    },
    addMonth () {
      this.dateContext = moment(this.dateContext.add(1, 'month'))
      this.monthChange()
    },
    monthChange () {
      //this.$emit('changeMonth', {'begin': this.beginOfMonth, end: this.endOfMonth})
      this.selectedDate = null
      this.$emit('input', null)
      this.$emit('changeMonth', this.beginOfMonth)
    },
    selectDate (date) {
      this.$emit('input', date)
      this.selectedDate = moment(date)
    },
    dayClasses (day) {
      if (day.otherMonth) {
        return 'blank'
      }
      if (day.selected && day.active) {
        return 'selected'
      }
      return day.active ? 'active' : 'inactive'
    }
  },
  computed: {
    beginOfMonth () {
      return this.dateContext.clone().startOf('month')
    },
    endOfMonth () {
      return this.dateContext.clone().endOf('month')
    },
    displayDays () {
      let weekDayBegin = this.beginOfMonth.weekday();
      let weekDayEnd = this.endOfMonth.weekday()
      let daysInMonth = this.dateContext.daysInMonth();
      let numItems = weekDayBegin + daysInMonth + 6 - weekDayEnd
      let days = []
      for (let i = 0; i < numItems; i++) {
        let sameMonth = this.dateContext.clone().startOf('month').add(i - weekDayBegin, 'days').isSame(this.dateContext, 'month')
        let currentDay = this.dateContext.clone().startOf('month').add(i - weekDayBegin, 'days')
        let hasSlot = !!this.slots.find(slot => moment(slot.begin).isSame(currentDay, 'day'))
        let isSelected = currentDay.isSame(this.selectedDate, 'day')
        days.push({
          id: i,
          otherMonth: !sameMonth,
          active: hasSlot,
          selected: isSelected,
          date: sameMonth ? currentDay.toDate() : null,
          day: sameMonth ? currentDay.format('DD') : null
        })
      }
      return days
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/config";
.calendar {
    display: flex;
    flex-wrap: wrap;
    /*border: 1px solid lightgrey;*/
    padding: 1rem;
    //border-radius: 3px;
    /*height:12rem;*/

    .dayHead {
        min-width: (100% / 7);
        max-width: (100% / 7);
        line-height: 2rem;
        text-align: center;
    }

    .controls {
        flex-grow: 1;
        height: 2.5rem;
        line-height: 2.3rem;
        min-width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: space-evenly;

        .arrow {
            flex-grow: 1;
            text-align: center;
            line-height: 1.8rem;
            font-size: 1.5rem;
        }
    }

    .days {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        min-width: 100%;
        max-width: 100%;
        flex-grow: 1;

        .day {
            min-width: (100% / 7);
            max-width: (100% / 7);
            background-color: whitesmoke;
            border: 1px solid white;
            border-radius: 2px;
            box-sizing: border-box;
            text-align: center;
            line-height: 2.2rem;
            height: 2.2rem;
            transition: all .2s ease;

            a {
                display: block;
                width: 100%;
                height: 100%;
                color: black;
            }

            &.blank {
                background-color: transparent;
            }
            &.active {
                background-color: #95f190;
                :hover {
                    background-color: #72ed84;
                }
            }
            &.inactive {
                background-color: #ffcac8;
                opacity: .5;
            }

            &.selected {
                background-color: $selected;
                color: white;
                a {
                    color: white;
                }
            }
        }
    }
    &.isLoading {
        .days .day {
            background-color: whitesmoke !important;
            opacity: 0.5;
        }
    }
}

.loader-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 0.2rem solid darkgrey; /* Light grey */
    border-top: 0.2rem solid white; /* Blue */
    border-right: 0.2rem solid white;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    animation: spin 0.6s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
<template>
  <div id="app">
    <basic-layout>
      <router-view/>
    </basic-layout>
  </div>
</template>
<script>
  import BasicLayout from "./components/BasicLayout";

  export default {
    components: {BasicLayout}
  }
</script>
<style lang="scss">
  @import "assets/config";
  @import "~bulma";
  @import "~buefy/src/scss/buefy";


  a {
    color: $link;
  }

  .isLoadingCalendar .dropdown-menu::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
  }

  .offering h2 {
    font-size: 1.15rem;
    font-weight: 600;
    margin-bottom: 0.5em;
  }

  .hovercard {
    transition: all .2s ease;
  }

  .hovercard:hover {
    background-color: whitesmoke;
  }

  .is-selected {
    background-color: $selected !important;
    color: white !important;

    a {
      color: white;
    }
  }

  .is-full-height {
    height: 100%;
  }

  .headline {
    font-size: 1.5em;
    font-weight: 400;
    border-bottom: 1px #e1e4e8 solid;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;

    margin-top: 3rem;
  }

  .headline.is-first {
    margin-top: 0;
  }

  .headline.is-borderless {
    border: 0;
    margin-bottom: 0.2rem;
  }


</style>


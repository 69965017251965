<template>
  <div>
    <template v-if="!showApprove">
      <div class="headline is-borderless is-first">Kategorie wählen</div>
      <div class="columns is-multiline is-paddingless is-relative">
        <b-loading :is-full-page="false" :active="$apollo.queries.OfferingGroups.loading"></b-loading>
        <div v-for="OfferingGroup in OfferingGroups.filter(og => og.offerings.length > 0)"
             class="column is-one-third is-full" :key="OfferingGroup.id">
          <a @click="selectOfferingGroup(OfferingGroup.id)">
            <div class="card hovercard offering cardShadow is-full-height"
                 :class="{'is-selected': OfferingGroupId === OfferingGroup.id}">
              <div class="card-content">
                <a><h2>{{ OfferingGroup.formInfoName }}</h2></a>
                <div style="white-space: pre-wrap; ">{{ OfferingGroup.formInfoDescription }}</div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div ref="step1" style="margin-top:-.75em;">
        <template v-if="!!OfferingGroupId">
          <div class="headline is-borderless">Angebot wählen</div>
          <div class="columns is-multiline is-paddingless">
            <div v-for="EventType in EventTypes" class="column is-full is-half-desktop"
                 :key="EventType.id" v-if="selectedOfferingGroup.isManual">
              <a @click="selectEventType(EventType.id)">
                <div class="card hovercard offering cardShadow is-full-height"
                     :class="{'is-selected': eventTypeId === EventType.id}">
                  <div class="card-content">
                    <a><h2>{{ EventType.formInfoName }}</h2></a>
                    <div class="description" v-html="autoLink(EventType.formInfoDescription)"/>
                  </div>
                </div>
              </a>
            </div>
            <div v-for="Offering in Offerings" class="column is-full  is-half-desktop "
                 :key="Offering.id" v-if="!selectedOfferingGroup.isManual">
              <a @click="selectOffering(Offering.id)">
                <div class="card hovercard offering cardShadow is-full-height"
                     :class="{'is-selected': OfferingId === Offering.id}">
                  <div class="card-content">
                    <a><h2>{{ Offering.formInfoName }}</h2></a>
                    <div class="description" v-html="autoLink(Offering.formInfoDescription)"/>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </template>
      </div>
      <div ref="step2">
        <template v-if="isMultiPerson">
          <div class="headline is-borderless">Personenzahl wählen</div>
          <b-select v-model="numberOfPeople" @input="slotId = null" expanded>
            <option v-for="num in selectedOffering.defaultQuota" :value="num">{{ num }} Personen
            </option>
          </b-select>
        </template>
        <template v-if="!!OfferingId">
          <div class="headline is-borderless">Datum wählen</div>
          <datepicker @changeMonth="changeMonth" :slots="filteredTimes" :loading="isLoading"
                      class="cardShadow"
                      @input="selectDate"></datepicker>
          <div class="headline is-borderless">Uhrzeit wählen</div>
          <div class="columns is-multiline">
            <div class="column" v-if="selectedDate === null">
              <div class="card cardShadow">
                <div class="card-content has-text-centered" style="opacity: 0.7;">
                  Bitte wählen Sie zuerst ein Datum aus.
                </div>
              </div>
            </div>
            <a class="column is-one-third" v-for="slot in selectableTimes">
              <a @click="selectSlot(slot.id)">
                <div class="card hovercard cardShadow"
                     :class="{'is-selected': slotId === slot.id}">
                  <div class="card-content has-text-centered" style="font-size:1.15rem;">
                    {{ slot.begin | moment('HH:mm') }} - {{ slot.end | moment('HH:mm') }}
                  </div>
                </div>
              </a>
            </a>
          </div>
        </template>
        <template v-if="!!selectedEventType">
          <div class="headline is-borderless">Veranstaltung wählen</div>
          <div class="columns is-multiline">
            <div v-for="event in Events"
                 class="column is-half-desktop is-full-mobile"
                 :key="event.id">
              <a @click="selectEvent(event.id)">
                <div class="card hovercard offering cardShadow is-full-height"
                     :class="{'is-selected': eventId === event.id, 'bookedOut': event.freePlaces < 1}">
                  <div class="card-content">
                    <div style="margin-bottom:.5em;" v-if="!!event.description">{{ event.description }}</div>
                    <span v-if="event.dates.length > 1">Bestehend aus mehreren Terminen:</span>
                    <div v-for="(date, index) in event.dates" :key="index" class="has-text-weight-bold dates">
                      {{ date.begin | moment('DD. MMMM') }},
                      {{ date.begin | moment('HH:mm') }} - {{ date.end | moment('HH:mm') }} Uhr
                    </div>

                    <div v-if="event.freePlaces < 1" class="has-text-danger" style="margin-top:.25em;">AUSGEBUCHT</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </template>
      </div>
    </template>
    <keep-alive>
      <registration-form v-if="(!!selectedTime || !!selectedEvent) && !showApprove"
                         :selectedOffering="selectedOffering"
                         :selectedTime="selectedTime"
                         :selectedEvent="selectedEvent"
                         ref="step3"
                         @submit="approve"/>
    </keep-alive>
    <confirm-box v-if="showApprove"
                 :regInfo="regInfo"
                 :selectedOffering="selectedOffering"
                 :selectedEvent="selectedEvent"
                 @cancel="cancel"/>
  </div>
</template>

<script>
import GET_OFFERING_GROUPS_QUERY from '../graphql/OfferingGroups.gql'
import GET_AVAILABLE_TIMES_QUERY from '../graphql/AvailableTimes.gql'
import RegistrationForm from '../components/RegistrationForm'
import ConfirmBox from '../components/ConfirmBox'
import moment from 'moment'
import Datepicker from '../components/DatePicker'
import BasicLayout from '../components/BasicLayout'

export default {
  components: {
    BasicLayout,
    ConfirmBox,
    RegistrationForm,
    Datepicker
  },
  props: ['id'],
  name: 'app',
  data() {
    return {
      isLoading: 0,
      OfferingGroups: [],
      AvailableTimes: [],
      currentDate: new Date(),
      showApprove: false,
      selectedDate: null,
      OfferingId: null,
      OfferingGroupId: null,
      slotId: null,
      eventTypeId: null,
      eventId: null,
      person: {},
      numberOfPeople: 1
    }
  },
  methods: {
    strip(html) {
      let doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || "";
    },
    autoLink(text) {
      let pattern = /(^|[\s\n]|<[A-Za-z]*\/?>)((?:https?|ftp):\/\/[\-A-Z0-9+\u0026\u2019@#\/%?=()~_|!:,.;]*[\-A-Z0-9+\u0026@#\/%=~()_|])/gi;
      return this.strip(text).replace(pattern, "$1<a v-on:click.stop='dothis' href='$2' target='_blank'>$2</a>");
    },
    clearSelection() {
      this.eventId = null
      this.eventTypeId = null
      this.slotId = null
      this.selectedDate = null
      this.numberOfPeople = 1
      this.OfferingId = null
    },
    selectOfferingGroup(id) {
      this.clearSelection()
      this.OfferingGroupId = id;
      this.$nextTick().then(() => {
        this.$scrollTo(this.$refs.step1, 1500)
      })
    },
    selectOffering(id) {
      this.clearSelection()
      this.OfferingId = id
      this.$router.replace({name: 'registration', params: {id: "o" + id}})
      this.$nextTick().then(() => {
        this.$scrollTo(this.$refs.step2, 1500)
      })
    },
    selectEventType(id) {
      this.eventId = null
      this.eventTypeId = id
      this.$router.replace({name: 'registration', params: {id: "e" + id}})
      this.$nextTick().then(() => {
        this.$scrollTo(this.$refs.step2, 1500)
      })
    },
    selectSlot(id) {
      this.eventId = null
      this.slotId = id
      this.$nextTick().then(() => {
        this.$scrollTo(this.$refs.step3.$el, 1500)
      })
    },
    selectEvent(id) {
      if (this.Events.find(e => e.id === id).freePlaces < 1) return;
      this.slotId = null
      this.OfferingId = null
      this.eventId = id
      this.$nextTick().then(() => {
        this.$scrollTo(this.$refs.step3.$el, 1500)
      })
    },
    changeMonth(date) {
      this.slotId = null
      this.currentDate = moment(date)
    },
    sameDay(d1, d2) {
      return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    },
    approve(person) {
      this.person = person
      this.showApprove = true
    },
    cancel() {
      this.showApprove = false
    },
    selectDate(date) {
      this.slotId = null
      this.selectedDate = date
    }
  },
  apollo: {
    OfferingGroups: {
      query: GET_OFFERING_GROUPS_QUERY,
      watchLoading(loading) {
        let id = this.id
        if (id && !loading) {
          const letter = id[0]
          const rest = id.slice(1)
          console.log(letter, rest)
          this.$nextTick(() => {
            if (letter === "o") {
              let og = this.OfferingGroups.find(og => (!og.isManual) && og.offerings.find(o => o.id === rest))
              if (og) {
                this.OfferingGroupId = og.id
                this.OfferingId = rest
                this.$nextTick().then(() => {
                  this.$scrollTo(this.$refs.step2, 1500)
                })
              }

            } else if (letter === "e") {
              let et = this.OfferingGroups.find(og => og.isManual && og.offerings.find(o => o.eventTypes.find(e => e.id === rest)))
              if (et) {
                this.OfferingGroupId = et.id
                this.eventTypeId = rest
                this.$nextTick().then(() => {
                  this.$scrollTo(this.$refs.step2, 1500)
                })
              }
            }
          })
        }
      }
    },
    AvailableTimes: {
      query: GET_AVAILABLE_TIMES_QUERY,
      variables() {
        return {
          offeringId: this.OfferingId,
          begin: this.queryBegin,
          end: this.queryEnd
        }
      },
      loadingKey: 'isLoading',
      skip() {
        return !this.OfferingId
      }
    }
  },
  watch: {},
  computed: {
    Offerings() {
      if (!this.OfferingGroupId) return [];
      return this.OfferingGroups.find(og => og.id === this.OfferingGroupId).offerings.slice().sort((a, b) => a.formInfoName.localeCompare(b.formInfoName))
    },
    EventTypes() {
      if (!this.selectedOfferingGroup || !this.selectedOfferingGroup.isManual) return [];
      return this.Offerings.reduce((accu, curr) => [...accu, ...curr.eventTypes], []).slice().sort((a, b) => a.formInfoName.localeCompare(b.formInfoName))
    },
    Events() {
      if (!this.eventTypeId) return [];
      return this.selectedEventType.events;
    },
    queryBegin() {
      return moment(this.currentDate).startOf('month').toDate()
    },
    queryEnd() {
      return moment(this.currentDate).endOf('month').toDate()
    },
    filteredTimes() {
      return this.AvailableTimes.filter(time => time.countFreePlaces >= this.numberOfPeople)
    },
    selectableTimes() {
      return this.selectedDate ? this.filteredTimes.filter((time) => {
        let begin = new Date(time.begin);
        return this.sameDay(begin, this.selectedDate)
      }) : []
    },
    selectedTime() {
      return this.AvailableTimes.find(time => time.id === this.slotId)
    },
    selectedEvent() {
      return this.Events.find(e => e.id === this.eventId)
    },
    selectedOfferingGroup() {
      return this.OfferingGroups.find(og => og.id === this.OfferingGroupId)
    },
    selectedOffering() {
      if (this.eventTypeId) {
        return this.Offerings.find(o => !!o.eventTypes.find(e => e.id === this.eventTypeId))
      }
      return this.Offerings.find(o => o.id === this.OfferingId)
    },
    selectedEventType() {
      if (this.eventTypeId) {
        return this.EventTypes.find(et => et.id === this.eventTypeId)
      }
    },
    isMultiPerson() {
      let offering = this.Offerings.find(offering => offering.id === this.OfferingId)
      return offering ? offering.isMultiPersonOffering : false
    },
    regInfo() {
      return {
        slot: this.selectedTime,
        event: this.selectedEvent,
        person: {
          ...this.person,
          numberOfPeople: this.numberOfPeople
        }
      }
    }
  }
}
</script>

<style lang="scss">
.description {
  white-space: pre-wrap;

  a {
    overflow: hidden;
    width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
  }
}
</style>
